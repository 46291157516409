<template>
  <main class="mt-5">
    <div class="container-logo">
      <!-- <div class="container-logo-flex">
        <img :src="'/images/logo2.png'" class="company-logo" />
      </div> -->
      <div class="container-description">
        <p class="text-uppercase-pre">
          REGISTRO PRE-UNIVERSITARIO
        </p>
      </div>
    </div>
    <FormPreuniversitarioPartials></FormPreuniversitarioPartials>
  </main>
</template>

<script>
import FormPreuniversitarioPartials from './partials/FormPreuniversitario.partials.vue';
export default {
  components: {
    FormPreuniversitarioPartials,
  },
  created() {},
};
</script>

<style land="scss">
@import "../styles/comunitypreuniversitaria.scss";
</style>
